<template>
  <div class="content_body Storage" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="22">
          <el-form :inline="true" size="small" :model="searchForm">
            <el-form-item label="仓库/门店" v-if="purchaseStorage.length>1">
              <el-select v-model="searchForm.EntityID" :default-first-option="true" @change="handleSearchStorageClick" @clear="handleSearchStorageClick" clearable filterable placeholder="请选择仓库">
                <el-option v-for="item in purchaseStorage" :key="item.ID" :label="item.EntityName" :value="item.ID"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品名称">
              <el-input v-model="searchForm.Name" placeholder="输入产品名称、别名搜索" clearable @clear="handleSearchStorageClick" @keyup.enter.native="handleSearchStorageClick"></el-input>
            </el-form-item>
            <el-form-item label="供应商名称">
              <el-select v-model="searchForm.SupplierID" :default-first-option="true" @change="handleSearchStorageClick" clearable filterable placeholder="请选择供应商">
                <el-option v-for="item in StorageList" :key="item.ID" :label="item.Name" :value="item.ID"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="制单日期">
              <el-date-picker v-model="searchForm.createTime" unlink-panels type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" @change="handleSearchStorageClick"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearchStorageClick" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="2" class="text_right">
          <el-button type="primary" @click="addStorageOrderClick" size="small" v-prevent-click>采购入库</el-button>
        </el-col>
      </el-row>
    </div>

    <el-table class="martp_10" size="small" :data="storageList" tooltip-effect="light">
      <el-table-column prop="ID" label="单据号"></el-table-column>
      <el-table-column prop="EntityName" label="仓库/门店"></el-table-column>
      <el-table-column prop="InventoryType" label="入库类型"></el-table-column>
      <el-table-column prop="SupplierName" label="供应商"></el-table-column>
      <el-table-column prop="CreatedOn" label="制单时间"></el-table-column>
      <!-- <el-table-column prop="InDate" label="入库时间"></el-table-column> -->
      <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
      <el-table-column prop="Remark" label="备注信息" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" width="80">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="showStorageInfoClick(scope.row)" v-prevent-click>详情</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pad_15 text_right">
      <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
    </div>

    <!-- 创建采购入库 -->
    <el-dialog title="采购入库" :visible.sync="dialogVisible" width="1100px" @closed="cancelAddSubmitStorageClick('addStorageForm')">
      <div class="tip marbm_10" style="margin-top:0">采购信息</div>
      <el-form label-width="120px" size="small" class="storageInfoFrom" :model="addStorageForm" :rules="addStorageRulesMsg" ref="addStorageForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="仓库/门店" prop="EntityID">
              <el-select value-key="ID" v-model="addStorageForm.EntityName" filterable placeholder="请选择仓库/门店" @change="handleSelectProductEntity">
                <el-option value-key="ID" v-for="item in purchaseStorage" :key="item.ID" :label="item.EntityName" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="入库时间" prop="InDate">
              <el-date-picker format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" v-model="addStorageForm.InDate" type="datetime" :picker-options="pickerOptions" placeholder="选择入库日期"></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="供应商名称">
              <el-select value-key="ID" v-model="addStorageForm.SupplierName" clearable filterable placeholder="请选择供应商" @change="handleSelectProductStorage">
                <el-option v-for="item in StorageList" :key="item.ID" :label="item.Name" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="备注信息">
              <el-input type="textarea" style="width:300px" :autosize="{ minRows: 1, maxRows: 3 }" v-model="addStorageForm.Remark" placeholder="请输入备注信息" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="tip marbm_10" style="margin-top:0">采购明细</div>
      <el-row>
        <el-col :span="4">
          <el-button type="primary" size="small" @click="addProducts">添加产品</el-button>
          <el-button type="danger" size="small" @click="removeMultipleProduct" :disabled="removeDisabled">删除产品</el-button>
        </el-col>
      </el-row>

      <el-form :model="addStorageForm" :rules="addProductItemRules" size="small" :inline="true" :show-message="false" class="productFormInforClass" ref="addStorageFormProduct">
        <el-table empty-text="暂无产品" size="small" class="martp_15" max-height="300px" :data="addStorageForm.Product" @selection-change="handleChangeSelectProduct">
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column label="产品名称">
            <template slot-scope="scope">
              <el-form-item :prop="'Product.' + scope.$index + '.ProductID'" :rules="addProductItemRules.ProductID">
                <el-select v-model="scope.row.ID" size="small" filterable remote reserve-keyword v-loadmore="loadMoreProduct" placeholder="请选择产品" :remote-method="searchProductListMethod" :loading="productLoading" @change="(val)=>handleSelectProduct(val,scope.row)" @focus="selectFocus" @clear="clearSelectProduct(scope.row)" :default-first-option="true" popper-class="Storage_custom_popper_class">
                  <el-option v-for="item in allProductList" :key="item.ID" :label="item.ProductName" :value="item.ID" :disabled="item.IsLock">
                    <div class="dis_flex flex_dir_column pad_5_0">
                      <div>
                        <span>{{ item.ProductName }}</span>
                        <span class="color_gray marlt_5 font_12" v-if="item.Alias">({{ item.Alias }})</span>
                        <el-tag v-if="item.IsLock" size="mini" type="warning" effect="dark">{{'盘点锁定'}}</el-tag>
                      </div>
                      <div :class="item.ID == scope.row.ID?'font_12 dis_flex  flex_x_between' : 'color_gray font_12 dis_flex flex_x_between'">
                        <span class="">{{ item.PCategoryName }}</span>
                        <span v-if="item.Specification">{{ item.Specification }}</span>
                      </div>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="Specification" label="产品规格">
            <template slot-scope="scope">{{scope.row.Specification}}</template>
          </el-table-column>
          <el-table-column label="采购单位">
            <template slot-scope="scope">
              <el-form-item :prop="'Product.' + scope.$index + '.UnitName'" :rules="addProductItemRules.UnitID">
                <el-select value-key="UnitID" v-model="scope.row.Unit" size="small" filterable placeholder="采购单位" @change="(val)=>handleSelectProductUnit(val,scope.row)" @clear="clearSelectProductUnit(scope.row)" :default-first-option="true">
                  <el-option v-for="item in scope.row.Units" :key="item.UnitID" :label="item.UnitName" :value="item"></el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="Quantity" label="采购单位数量">
            <template slot-scope="scope">
              <el-form-item :prop="'Product.' + scope.$index + '.Quantity'" :rules="addProductItemRules.Quantity">
                <el-input v-model="scope.row.Quantity" size="small" placeholder="采购单位数量" type="number" v-enterInt v-enterNumber2 v-enter-int 
                v-enterNumber @input="changeQuantity(scope.row)" min="1"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="UnitPrice" label="采购单位单价(元)">
            <template slot-scope="scope">
              <el-form-item :prop="'Product.' + scope.$index + '.UnitPrice'" :rules="addProductItemRules.UnitPrice">
                <el-input v-model="scope.row.UnitPrice" size="small" placeholder="采购单位单价" @input="changeUnitPrice(scope.row)" validate-event v-enter-number2 min="0" type="number"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="Amount" label="小计(元)">
            <template slot-scope="scope">{{scope.row.Amount | NumFormat}}</template>
          </el-table-column>
          <el-table-column prop="MinimumUnitQuantity" label="最小包装数量" :formatter="MinimumUnitQuantityAndUnit"></el-table-column>
        </el-table>
      </el-form>

      <div slot="footer">
        <el-button @click="cancelAddSubmitStorageClick('addStorageForm')" size="small" v-prevent-click>取 消</el-button>
        <el-button type="primary" @click="addSubmitStorageClick('addStorageForm')" :loading="modalLoading" size="small" v-prevent-click>确认入库</el-button>
      </div>
    </el-dialog>

    <!-- 采购入库详情 -->
    <el-dialog title="采购入库详情" :visible.sync="dialogVisibleInfo" width="1000px">
      <div class="tip">采购信息</div>
      <el-form label-width="120px" size="small" class="storageInfoDetailFrom" :model="addStorageForm">
        <el-row>
          <el-col :span="8">
            <el-form-item label="单据号：">{{storageInfoDetailForm.ID}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="仓库/门店：">{{storageInfoDetailForm.EntityName}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="操作人：">{{storageInfoDetailForm.EmployeeName}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="供应商：">{{storageInfoDetailForm.SupplierName}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入库时间：">{{storageInfoDetailForm.InDate}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="制单时间：">{{storageInfoDetailForm.CreatedOn}}</el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注信息：">{{storageInfoDetailForm.Remark}}</el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="tip">采购明细</div>
      <el-table size="small" class="martp_10" max-height="300px" :data="storageInfoDetailForm.Detail">
        <el-table-column label="产品" prop="ProductName">
          <template slot-scope="scope">
            <div>{{scope.row.ProductName}}<span v-if="scope.row.Alias"  class="color_gray font_12">({{scope.row.Alias}})</span></div>
            <div v-if="scope.row.Specification" class="color_gray font_12">规格：{{scope.row.Specification}}</div>
          </template>
        </el-table-column>
        <el-table-column label="产品分类" prop="PCategoryName"></el-table-column>
        <!-- <el-table-column label="产品规格" prop="Specification"></el-table-column> -->
        <el-table-column label="采购数量" prop="Quantity">
          <template slot-scope="scope">
            <div>{{scope.row.Quantity}} {{scope.row.UnitName}}</div>
            <div class="color_gray font_12">最小包装数量：{{MinimumUnitQuantityAndUnit(scope.row)}}</div>
          </template>
        </el-table-column>
        <el-table-column label="采购单价(元)">
          <template slot-scope="scope">{{scope.row.UnitPrice | NumFormat}}</template>
        </el-table-column>
        <el-table-column label="小计(元)">
          <template slot-scope="scope">{{scope.row.Amount | NumFormat}}</template>
        </el-table-column>
      </el-table>

      <div slot="footer">
        <el-button @click="cashierReceiptDialogVisible = true" size="small" v-prevent-click type="primary">打印小票</el-button>
        <el-button @click="dialogVisibleInfo = false" size="small" v-prevent-click>取 消</el-button>
      </div>
    </el-dialog>
    <!-- 入库详情小票打印 -->
     <el-dialog :visible.sync="cashierReceiptDialogVisible" width="300px">
      <span slot="title" class="font_14 color_333">打印小票</span>
      <div v-if="storageInfoDetailForm && userInfo">
        <el-row>
          <el-col :span="24">
            <el-scrollbar class="el-scrollbar_height" style="height:500px">
              <div class="marrt_10">
                <div class="dis_flex">
                  <span
                    class="flex_box text_center font_16"
                    style="line-height:32px"
                  >{{userInfo.EntityName}}</span>
                </div>
                <el-divider>
                  <span class="font_12 color_gray">订单信息</span>
                </el-divider>

                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">单据号</span>
                  <span
                    class="font_12 text_right line_height_24"
                    style="flex:3;"
                  >{{storageInfoDetailForm.ID}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">仓库/门店</span>
                  <span
                    class="flex_box font_12 text_right line_height_24"
                  >{{storageInfoDetailForm.EntityName}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">供应商</span>
                  <span
                    class="flex_box font_12 text_right line_height_24">{{storageInfoDetailForm.SupplierName}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">入库时间</span>
                  <span
                    class="font_12 text_right line_height_24"
                    style="flex:3;"
                  >{{storageInfoDetailForm.InDate}}</span>
                </div>
                <el-divider>
                  <span class="font_12 color_gray">采购明细</span>
                </el-divider>
                <template v-for="(item,index) in storageInfoDetailForm.Detail">
                  <div :key="index + 'Detail' + item.ProductID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                      {{item.ProductName}}
                      </span>
                      <span
                        class="font_12 text_right line_height_24"
                        style="flex:1;"
                      >￥{{item.Price | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span
                        class="font_12 color_gray text-left line_height_24 marlt_10"
                        style="flex:2;"
                      >数量</span>
                      <span
                        class="font_12 text_right line_height_24"
                        style="flex:1;"
                      >{{item.Quantity+item.UnitName}}</span>
                    </div>
                    <div class="dis_flex">
                      <span
                        class="font_12 color_gray text-left line_height_24 marlt_10"
                        style="flex:2;"
                      >小计</span>
                      <span
                        class="font_12 text_right line_height_24"
                        style="flex:1;"
                       
                      >￥{{item.Amount | NumFormat}}</span>
                    </div>
                     <el-divider>
                
                </el-divider>
                  </div>
                  
                </template>
                <!-- <el-divider class="sell-el-divider"></el-divider> -->
               
              </div>
            </el-scrollbar>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="cashierReceiptDialogVisible = false;"
          size="small"
          :disabled="modalLoading"
        >取 消</el-button>
        <el-button
          type="primary"
          @click="submitPrint"
          :loading="modalLoading"
          v-prevent-click
          size="small"
        >打印</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import APIStorage from "@/api/PSI/Purchase/storage";
import APIStock from "@/api/PSI/Inventory/inventoryDetail";
import printReceipt from "@/components/js/print";
import date from "@/components/js/date";
var Enumerable = require("linq");
var socket;
export default {
  name: "Storage",
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      userInfo:{},
      modalLoading: false,
      cashierReceipt: "", //小票配置信息
      cashierReceiptDialogVisible: false, //小票弹窗
      ReceiptContent:{}, //小票内容
      loading: false,
      dialogVisible: false,
      dialogVisibleInfo: false,
      modalLoading: false,
      productLoading: false,
      removeDisabled: true,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() ? true : false;
        },
      },
      searchForm: {
        ID: "", // 单据号
        Name:"",//产品名称
        EntityID: "", //仓库id
        StorageID: "", // 供应商id
        createTime: "", //时间
      },
      storageList: [], //库存列表
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next, jumper", // 翻页属性
      }, //需要给分页组件传的信息
      purchaseStorage: [], // 仓库列表
      StorageList: [], //供应商列表
      allProductList: [], //可添加的产品列表
      addStorageRulesMsg: {
        InDate: [
          {
            required: true,
            message: "请选择入库时间",
            trigger: ["blur", "change"],
          },
        ],
        EntityID: [
          {
            required: true,
            message: "请选择仓库/门店",
            trigger: ["blur", "change"],
          },
        ],
      }, //创建入库 验证规则
      addStorageForm: {
        // InDate: date.formatDate.format(new Date(), "YYYY-MM-DD hh:mm:ss"), //入库时间
        InDate: new Date(), //入库时间
        EntityID: "", //仓库ID
        EntityName: "",
        SupplierID: "", //供应商ID
        SupplierName: "",
        Remark: "", // 备注
        Product: [
          // {
          //   ProductID: "",
          //   UnitID: "",
          //   UnitPrice: "",
          //   Quantity: "",
          //   Amount: "",
          //   MinimumUnitID: "",
          //   MinimumUnitName: "",
          //   MinimumUnitQuantity: "",
          //   UnitAmount: "",
          //   Units: [],
          // },
        ], // 产品列表
      },
      addProductItemRules: {
        ProductID: [{ required: true, trigger: ["blur", "change"] }],
        UnitID: [{ required: true, trigger: ["blur", "change"] }],
        UnitPrice: [{ required: true, trigger: ["blur", "change"] }],
        Quantity: [{ required: true, trigger: ["blur", "change"] }],
      }, //产品验证规则
      storageInfoDetailForm: {}, //入库详情
      multipleProducts: [], //勾选的产品明细
      productPageNum: 1,
      allProducTotal: 0,
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    /**  点击事件  */
    /**  搜索  */
    handleSearchStorageClick() {
      this.paginations.page = 1;
      this.getpurchaseStorageListNetwork();
    },
    /**  修改页码  */
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.getpurchaseStorageListNetwork();
    },
    /**  查看详情  */
    showStorageInfoClick(row) {
      var that = this;
      that.dialogVisibleInfo = true;
      that.loading = true;
      that.getStorageInfoNetwork(row.ID);
    },
    /**  添加 采购  */
    addStorageOrderClick() {
      var that = this;
      that.dialogVisible = true;
      that.addStorageForm = {
        InDate: date.formatDate.format(new Date(), "YYYY-MM-DD hh:mm:ss"), //入库时间
        EntityID: "", //仓库ID
        EntityName: "",
        SupplierID: "", //供应商ID
        SupplierName: "",
        Remark: "", // 备注
        Product: [], // 产品列表
      };
      if (that.purchaseStorage.length == 1) {
        //当仓库/门店数量为1，默认选中
        that.addStorageForm.EntityID = that.purchaseStorage[0].ID;
        that.addStorageForm.EntityName = that.purchaseStorage[0].EntityName;
      }
    },

    /**添加采购入库 选择仓库   */
    handleSelectProductEntity(item) {
      this.addStorageForm.EntityID = item.ID;
      this.addStorageForm.EntityName = item.EntityName;
      this.addStorageForm.Product = [];
    },
    /** 添加供应商  */
    handleSelectProductStorage(item) {
      this.addStorageForm.SupplierID = item.ID;
      this.addStorageForm.SupplierName = item.Name;
    },

    /** 添加产品   */
    addProducts() {
      var that = this;
      that.$refs["addStorageForm"].validateField("EntityID", (valid) => {
        if (!valid) {
          that.addStorageForm.Product.push({
            ProductID: "",
            Unit: "",
            UnitID: "",
            UnitName: "",
            UnitPrice: "",
            Quantity: "",
            Amount: "",
            MinimumUnitID: "",
            MinimumUnitName: "",
            MinimumUnitQuantity: "",
            Specification: "",
            UnitAmount: "",
            Units: [],
          });
        }
      });
    },
    /**  批量删除  */
    removeMultipleProduct() {
      var that = this;
      if (that.multipleProducts.length > 0) {
        for (var i = 0; i < that.addStorageForm.Product.length; i++) {
          that.multipleProducts.forEach(function (item) {
            if (that.addStorageForm.Product[i] == item) {
              that.addStorageForm.Product.splice(i, 1);
            }
          });
        }
      }
    },

    /**  多选删除  */
    handleChangeSelectProduct(selection) {
      this.multipleProducts = selection;
      if (this.multipleProducts.length > 0) {
        this.removeDisabled = false;
      } else {
        this.removeDisabled = true;
      }
    },
    /** 获取焦点   */
    selectFocus() {
      this.searchProductListMethod("");
    },
    /**  下拉选择产品  */
    handleSelectProduct(itemID, row) {
      var that = this;
      row.Unit = "";
      row.UnitID = "";
      row.UnitName = "";
      row.UnitAmount = "";
      row.MinimumUnitQuantity = "";
      row.MinimumUnitID = "";
      row.MinimumUnitName = "";
      row.Specification = "";
      row.Quantity = "";
      row.UnitPrice = "";
      row.Amount = "";
      row.MinimumUnitQuantity = "";

      var item = Enumerable.from(that.allProductList)
        .where(function (p) {
          return itemID == p.ID;
        })
        .toArray()[0];
      item.Unit.forEach((unit) => {
        if (unit.IsDefautSendReceive) {
          row.Unit = unit;
          row.UnitID = unit.UnitID;
          row.UnitName = unit.UnitName;
          row.UnitAmount = unit.Amount;
          row.MinimumUnitQuantity = unit.Amount * row.Quantity;
        }
        if (unit.IsMinimumUnit) {
          row.MinimumUnitID = unit.UnitID;
          row.MinimumUnitName = unit.UnitName;
        }
      });
      row.ProductID = itemID;
      row.Units = item.Unit;
      row.Specification = item.Specification;
    },
    /** 清除所选商品   */
    clearSelectProduct(row) {
      console.log(
        "🚀 ~ file: storage.vue ~ line 620 ~ clearSelectProduct ~ row",
        row
      );
      // row.ProductID = "";
      // row.UnitID = "";
      // // row.UnitName = "";
      // row.UnitPrice = "";
      // row.Quantity = "";
      // row.Amount = "";
      // row.MinimumUnitID = "";
      // row.MinimumUnitName = "";
      // row.MinimumUnitQuantity = "";
      // row.UnitAmount = "";
      // row.Units = [];
      // this.searchProductListMethod(" ");
    },

    /**  选择产品单位  */
    handleSelectProductUnit(Unit, row) {
      row.UnitID = Unit.UnitID;
      row.UnitName = Unit.UnitName;
      row.UnitAmount = Unit.Amount;
      row.MinimumUnitQuantity = Unit.Amount * row.Quantity;
    },
    /**  清除所选产品单位  */
    clearSelectProductUnit(row) {
      row.UnitID = "";
      row.UnitName = "";
      row.UnitPrice = "";
      row.Quantity = "";
      row.Amount = "";

      row.MinimumUnitQuantity = "";
      row.UnitAmount = "";
    },
    /**  修改产品单价  */
    changeUnitPrice(row) {
      if (row.Quantity) {
        row.Amount = (row.Quantity * row.UnitPrice).toFixed(2);
      }
    },
    /**  修改产品采购数量  */
    changeQuantity(row) {
      row.Quantity = Math.floor(row.Quantity);
      row.MinimumUnitQuantity = row.UnitAmount * row.Quantity;
      if (row.UnitPrice) {
        row.Amount = (row.Quantity * row.UnitPrice).toFixed(2);
      }
    },

    /** 取消   */
    cancelAddSubmitStorageClick() {
      var that = this;
      that.$refs["addStorageForm"].resetFields();
      that.$refs["addStorageFormProduct"].resetFields();
      that.dialogVisible = false;
    },
    /**   保存入库 */
    addSubmitStorageClick() {
      var that = this;
      var isValid = false;
      if (that.addStorageForm.Product.length == 0) {
        that.$message.error({
          message: "请添加入库产品",
          duration: 2000,
        });
        return;
      }
      var isValidProduct = false;
      that.$refs["addStorageForm"].validate((valid) => {
        isValid = valid;
      });

      that.$refs["addStorageFormProduct"].validate((valid) => {
        isValidProduct = valid;
      });

      if (isValid && isValidProduct) {
        that.modalLoading = true;
        that.getStorageCreateNetwork(that.addStorageForm);
      }
    },
    /** 远程搜索产品   */
    searchProductListMethod(query) {
      var that = this;
      that.productPageNum = 1;
      that.allProductList = [];
      that.getStorageListNetwork(query);
    },
    /**  加载更多产品  */
    loadMoreProduct() {
      var that = this;
      if (that.allProducTotal > that.allProductList.length) {
        that.productPageNum++;
        that.getStorageListNetwork("");
      }
    },

    /**  格式化 最小包装单位数量  */
    MinimumUnitQuantityAndUnit(row) {
      if (row.Quantity) {
        return row.MinimumUnitQuantity + " " + row.MinimumUnitName;
      }
      return "";
    },

    /**  网络请求  */
    /**  4.1.采购入库列表  */
    getpurchaseStorageListNetwork: function () {
      var that = this;
      that.loading = true;

      var params = {
        PageNum: that.paginations.page,
        ID: that.searchForm.ID,
        ProductName: that.searchForm.Name,
        EntityID: that.searchForm.EntityID,
        SupplierID: that.searchForm.SupplierID,
        StartDate:
          that.searchForm.createTime == null
            ? ""
            : that.searchForm.createTime[0],
        EndDate:
          that.searchForm.createTime == null
            ? ""
            : that.searchForm.createTime[1],
      };
      APIStorage.getpurchaseStorageList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.storageList = res.List;
            that.paginations.page_size = res.PageSize;
            that.paginations.total = res.Total;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**  4.2.创建采购入库单 */
    getStorageCreateNetwork: function (params) {
      var that = this;
      APIStorage.getpurchaseStorageCreate(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.dialogVisible = false;
            that.getpurchaseStorageListNetwork();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    /**  4.3.采购入库单详情  */
    getStorageInfoNetwork: function (ID) {
      var that = this;
      var params = {
        ID: ID,
      };
      APIStorage.getpurchaseStorageInfo(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.storageInfoDetailForm = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
        that.getReceiptConfig();
    },
     /**  获取小票配置信息  */
    getReceiptConfig() {
      var that = this;
      APIStorage
        .getReceiptConfigBill()
        .then((res) => {
          if (res.StateCode == 200) {
            that.cashierReceipt = res.Data;
          }
        })
        .finally(() => {});
    },

    // 小票详情
    submitPrint(){
 var that = this;
      var params = {
        ID: that.storageInfoDetailForm.ID,
      };
      APIStorage.print(params)
        .then((res) => {
          if (res.StateCode == 200) {
             for (
              let index = 0;
              index < res.Data.copies;
              index++
            ) {
              printReceipt.doActionPrint(res.Data.printDocuments, (request) => {
                socket.send(JSON.stringify(request));
              });
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**  4.4.仓库列表  */
    getStorageEntityNetwork: function () {
      var that = this;
      that.loading = true;
      var params = {};
      APIStorage.getpurchaseStorageEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.purchaseStorage = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /** 4.5.供应商列表  */
    getStorageStorageNetwork: function () {
      var that = this;
      that.loading = true;
      var params = {};
      APIStorage.getpurchaseStorageSupplier(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.StorageList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**  4.6.采购入库产品列表 */
    getStorageListNetwork: function (query) {
      var that = this;
      var params = {
        PageNum: that.productPageNum,
        ProductName: query,
        EntityID: that.addStorageForm.EntityID,
      };
      APIStock.get_stock_list_entityProductListAll(params).then((res) => {
        if (res.StateCode == 200) {
          that.allProducTotal = res.Total;
          that.allProductList.push.apply(that.allProductList, res.List);
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
  },
  /**  实例被挂载后调用  */
  mounted() {
    var that = this;
    // var date = new Date(),
    //   y = date.getFullYear(),
    //   m = date.getMonth();
    // that.searchForm.createTime = [new Date(y, m, 1), new Date()];
    that.userInfo = JSON.parse(localStorage.getItem("access-user"));
    that.getpurchaseStorageListNetwork();
    that.getStorageEntityNetwork();
    that.getStorageStorageNetwork();
      socket = printReceipt.getSocket((res) => {
      if (res.status == "success") {
        that.$message.success({
          message: "打印成功",
          duration: 2000,
        });
        that.treatCashierReceiptDialogVisible = false;
        that.refundTreatCashierReceiptDialogVisible = false;
      }
    });
  },
};
</script>

<style lang="scss">
.Storage {
  .storageInfoFrom {
    .el-form-item__label {
      font-size: 13px !important;
    }
    .el-form-item__content {
      font-size: 13px !important;
    }
    // .el-form-item {
    //   margin-bottom: 5px;
    // }
  }
  .storageInfoDetailFrom {
    .el-form-item__label {
      font-size: 13px !important;
    }
    .el-form-item__content {
      font-size: 13px !important;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
  }

  .productFormInforClass {
    .el-form-item__label {
      font-size: 13px !important;
    }
    .el-form-item__content {
      font-size: 13px !important;
    }
    .el-form-item {
      margin-bottom: 0px;
    }

    .el-input__inner {
      padding-right: 0;
    }
  }
}

.Storage_custom_popper_class {
  .el-select-dropdown__item {
    line-height: normal;
    height: auto;
  }
}
 .el-scrollbar_height {
    height: 100%;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
</style>
